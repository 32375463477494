<template>
    <div>
        <v-card class="display-block" flat>
            <h3>Modify</h3>
            <v-card-text>
                <alert-message v-if="msg" :error="msg"></alert-message>
                <v-form ref="form" lazy-validation>
                    <v-tabs center-active show-arrows align-with-title :vertical="$vuetify.breakpoint.smAndUp"
                        :color="themeInverted.color">
                        <v-tab class="justify-start">
                            <v-icon left>mdi-cog-outline</v-icon>Basic
                        </v-tab>
                        <v-tab class="justify-start"
                            v-if="this.$store.getters.vendor?.licensingDetails?.allowedModules?.indexOf('order') > -1">
                            <v-icon left>mdi-truck-delivery-outline</v-icon>Delivery
                        </v-tab>
                        <v-tab class="justify-start"
                            v-if="this.$store.getters.vendor?.licensingDetails?.allowedModules?.indexOf('reward-system') > -1">
                            <v-icon left>mdi-wallet-giftcard</v-icon>Rewards
                        </v-tab>
                        <v-tab class="justify-start">
                            <v-icon left>mdi-bell-ring</v-icon>Notifications
                        </v-tab>
                        <!-- <v-tab class="justify-start"
                            v-if="this.$store.getters.vendor?.licensingDetails?.allowedModules?.indexOf('order') > -1">
                            <v-icon left>mdi-credit-card-outline</v-icon>Payments
                        </v-tab> -->
                        <v-tab-item :class="$vuetify.breakpoint.smAndUp ? 'mx-8' : ''">
                            <v-flex>
                                <v-text-field v-model="vendor.businessName" :rules="businessNameRules"
                                    label="Business Name*" required></v-text-field>
                                <v-text-field v-model="vendor.email" :rules="emailRules" label="Business email"
                                    type="Email address" />
                                <v-text-field v-model="vendor.mobileNo"
                                    label="Add multiple contact no. separated with coma"></v-text-field>
                                <v-textarea rows="2" v-model="vendor.description"
                                    label="Store Description"></v-textarea>
                                <v-text-field v-model="vendor.whatsappNo" label="Whatsapp Number"
                                    :rules="whatsappNoRules"></v-text-field>
                                <v-textarea v-model="vendor.whatsappMsg" label="Whatsapp Share Message"></v-textarea>
                                <label>Following URL will be appended with your
                                    message<br><b>{{ generateUrl(vendor.slug) }}</b></label><br><br>
                                <v-text-field v-model="vendor.taxPercent" label="Tax in %"></v-text-field>
                                <v-text-field v-model="vendor.address" label="Addess"></v-text-field>
                                <v-flex>
                                    Webstore UI Design
                                    <v-radio-group row mandatory v-model="vendor.storeUI">
                                        <span class="mr-5" v-for="row in storeUI" :key="row.item.toString()">
                                            <v-radio style="float:left;" :label="row.name"
                                                :value="row.name"></v-radio><br />
                                        </span>
                                    </v-radio-group>
                                </v-flex>
                                <v-layout wrap>
                                    <v-flex xs12 sm4 md3 class="pa-1" v-for="(image, index) in vendor.bannerImages"
                                        :key="image.filename" width="200">
                                        <image-card :src="image.path" @close="removeBannerImage(index)"
                                            :showClose="true"></image-card>
                                    </v-flex>
                                </v-layout>
                                <v-flex>
                                    <file-upload v-model="bannerImages" label="Upload Banner Images" :multiple="true"
                                        accept="image/*"></file-upload>
                                    <li v-for="(image, index) in bannerImages" :key="index">
                                        {{ image.name }}
                                    </li>
                                </v-flex>
                                <v-progress-linear v-if="progress > 0 && !msg" v-model="progress" color="orange"
                                    height="25">
                                    <strong>Uploading Images {{ progress }}%</strong>
                                </v-progress-linear>
                                <v-layout>
                                    <v-flex xs12 sm4 md3 class="pa-1" width="200">
                                        <image-card v-if="vendor.logo" :src="vendor.logo.path" @close="removeLogo()"
                                            :showClose="true"></image-card>
                                    </v-flex>
                                </v-layout>
                                <v-flex>
                                    <file-upload v-model="logo" label="Upload logo Image" :multiple="false"
                                        accept="image/*"></file-upload>
                                    <li v-for="(image, index) in logo" :key="index">
                                        {{ image.name }}
                                    </li>
                                </v-flex>
                                <v-checkbox v-model='vendor.showBanner' label="Show Banner"></v-checkbox>
                                <v-layout wrap mt-4>
                                    <v-flex sm3>
                                        <label>Theme Color:</label><br>
                                        <v-color-picker v-model="vendor.themeColor" dot-size="25" mode="hexa"
                                            hide-mode-switch></v-color-picker>
                                    </v-flex>
                                    <v-flex mx-4 sm3>
                                        <label>Font Color:</label><br>
                                        <v-color-picker v-model="vendor.fontColor" mode="hexa" dot-size="25"
                                            hide-mode-switch></v-color-picker>
                                    </v-flex>
                                    <v-flex mx-4 sm3>
                                        <label>Font Family</label><br>
                                        <font-picker :api-key="'AIzaSyCp6mcioPcK45ff3rbeUqdyj6pB4uC40pQ'"
                                            :active-font="fontFamily" @change="updateFont($event)">
                                        </font-picker>
                                    </v-flex>
                                </v-layout>
                                <v-flex v-if="vendor.storeUI == 'Webstore 1.0'">
                                    <br>
                                    <label>Display Products in mobile</label>
                                    <v-radio-group row mandatory v-model="vendor.imageInRow">
                                        <span class="mr-5" v-for="row in imageInRow" :key="row.item.toString()">
                                            <v-radio style="float:left;" :label="row.name"
                                                :value="row.item"></v-radio><br />
                                            <v-img width="280" height="420" :aspect-ratio="18 / 9"
                                                :src="row.img"></v-img>
                                        </span>
                                    </v-radio-group>
                                </v-flex>
                                <v-flex v-else-if="vendor.storeUI == 'Webstore 2.0'">
                                    <br>
                                    <label>Display Products in mobile</label>
                                    <v-radio-group row mandatory v-model="vendor.productCardMobile">
                                        <span class="mr-5" v-for="row in productCardMobile" :key="row.item.toString()">
                                            <v-radio style="float:left;" :label="row.name"
                                                :value="row.item"></v-radio><br />
                                            <v-img v-if="row.item == 1" width="350" height="80" :src="row.img"></v-img>
                                            <v-img v-else-if="row.item == 2" width="180" height="320"
                                                :src="row.img"></v-img>
                                        </span>
                                    </v-radio-group>
                                </v-flex>
                                <label>Social Media Links</label>
                                <v-layout wrap>
                                    <v-flex sm12 mt-3>
                                        <v-text-field prepend-icon="mdi-facebook" label="Facebook"
                                            v-model="vendor.facebook"></v-text-field>
                                    </v-flex>
                                    <v-flex sm12>
                                        <v-text-field prepend-icon="mdi-twitter" label="Twitter"
                                            v-model="vendor.twitter"></v-text-field>
                                    </v-flex>
                                    <v-flex sm12>
                                        <v-text-field prepend-icon="mdi-instagram" label="Instagram"
                                            v-model="vendor.instagram"></v-text-field>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-tab-item>
                        <v-tab-item :class="$vuetify.breakpoint.smAndUp ? 'mx-8' : ''"
                            v-if="this.$store.getters.vendor?.licensingDetails?.allowedModules?.indexOf('order') > -1">
                            <v-checkbox v-model="vendor.negativeStock"
                                label="Allow Negative Stock in Inventory"></v-checkbox>
                            <v-flex>
                                <br>
                                <label>Workflows:</label>
                                <v-layout wrap>
                                    <v-flex text-center xs12 sm6>
                                        <v-img :width="$vuetify.breakpoint.xsOnly ? 450 : 500"
                                            :height="$vuetify.breakpoint.xsOnly ? 130 : 200"
                                            :src="require('@/assets/workflow-1.png')"></v-img>
                                        <label>Workflow 1</label>
                                    </v-flex>
                                    <v-flex text-center xs12 sm5
                                        :class="$vuetify.breakpoint.xsOnly ? 'mt-10' : 'ml-12'">
                                        <v-img :width="$vuetify.breakpoint.xsOnly ? 300 : 480"
                                            :height="$vuetify.breakpoint.xsOnly ? 140 : 200"
                                            :src="require('@/assets/workflow-2.png')"></v-img>
                                        <label>Workflow 2</label>
                                    </v-flex>
                                </v-layout>
                            </v-flex><br>
                            <v-row>
                                <v-flex mx-3>
                                    <v-select @change="updateStoreStatus()" label="Select Webstore Order flow"
                                        v-model="vendor.storeWorkflowId" :items="workflows" item-text="title"
                                        item-value="_id"></v-select>
                                </v-flex>
                                <v-flex mx-3>
                                    <v-select v-model="vendor.storeStatus" label="Update inventory on"
                                        :items="storeSteps" item-text="source"></v-select>
                                </v-flex>
                            </v-row>
                            <v-row>
                                <v-flex mx-3>
                                    <v-select @change="updateCounterStatus()" label="Select Counter Order flow"
                                        v-model="vendor.counterWorkflowId" :items="workflows" item-text="title"
                                        item-value="_id"></v-select>
                                </v-flex>
                                <v-flex mx-3>
                                    <v-select v-model="vendor.counterStatus" label="Update inventory on"
                                        :items="counterSteps" item-text="source"></v-select>
                                </v-flex>
                            </v-row>
                            <v-checkbox v-model='vendor.enableSaleChallan' label="Enable Order Challan"></v-checkbox>
                            <v-flex v-if="vendor.enableSaleChallan">
                                <br>
                                <label>Supply Workflows:</label>
                                <v-layout wrap>
                                    <v-flex text-center xs12 sm6>
                                        <v-img :width="$vuetify.breakpoint.xsOnly ? 350 : 400"
                                            :height="$vuetify.breakpoint.xsOnly ? 100 : 150"
                                            :src="require('@/assets/supply_workflow_1.png')"></v-img>
                                        <label>Supply Workflow 1</label>
                                    </v-flex>
                                </v-layout>
                            </v-flex><br>
                            <v-row v-if="vendor.enableSaleChallan">
                                <v-flex mx-3>
                                    <v-select @change="updateSupplyStatus()" label="Select Order Supply flow"
                                        v-model="vendor.supplyWorkflowId" :items="supplyWorkflows" item-text="title"
                                        item-value="_id"></v-select>
                                </v-flex>
                            </v-row>
                            <v-layout wrap>
                                <v-flex>
                                    <v-autocomplete label="Delivery Options" @change="deliveryOptionUpdated()"
                                        v-model="vendor.delivery" item-text="label" :items="deliveryOptions" multiple
                                        return-object>
                                        <template v-slot:item="{ item }">
                                            <v-list-item-content v-text="item.label"></v-list-item-content>
                                            <v-icon @click="editLabel(item)">mdi-pencil</v-icon>
                                        </template>
                                    </v-autocomplete>
                                </v-flex>
                                <app-dialog :show="show" @close="show = !show">
                                    <v-text-field v-model="selectedOption.label"></v-text-field>
                                    <v-flex text-right>
                                        <v-btn @click="saveLabel()">Submit</v-btn>
                                    </v-flex>
                                </app-dialog>
                            </v-layout>
                            <v-flex v-if="enablePickup" mx-2>
                                <label>All Pickup Points</label>
                                <li class="my-2" v-for="(item, index) in vendor.pickupPoints" :key="index">
                                    <v-layout>
                                        <v-flex xs6 sm2><label class="subtitle-1">{{ item.name }}</label></v-flex>
                                        <v-flex xs1 sm1 text-center>
                                            <v-icon @click="editPickup(index)">mdi-pencil</v-icon><br>
                                        </v-flex>
                                        <v-flex>
                                            <v-icon @click="removePickupPoint(index)">mdi-close</v-icon><br>
                                        </v-flex>
                                    </v-layout>
                                    {{ item.address }}
                                </li>
                            </v-flex>
                            <v-layout my-2 v-if="enablePickup" wrap>
                                <v-flex sm5 xs12>
                                    <v-text-field label="Pickup Point name" v-model="pickupName"
                                        clearable></v-text-field>
                                </v-flex>
                                <v-flex sm6 :class="$vuetify.breakpoint.xsOnly ? '' : 'mx-3'">
                                    <v-text-field label="Pickup Point address" v-model="pickupAddress"
                                        clearable></v-text-field>
                                </v-flex>
                                <v-btn @click="addPickup()" :style="theme" fab small>
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </v-layout>
                            <v-text-field label="Delivery After(Days)" type="number"
                                v-model.number="vendor.deliveryDays"></v-text-field>
                            <v-text-field label="Delivery Charges(₹)" type="number"
                                v-model.number="vendor.deliveryCharge"></v-text-field>
                            <v-text-field label="Free Delivery on orders above(₹)" type="number"
                                v-model.number="vendor.freeDeliveryAbove"></v-text-field>
                            <v-text-field v-if="!deliveryOptionSelected()"
                                label="Delivery Message as no delivery Option is selected"
                                v-model="vendor.deliveryMsg"></v-text-field>
                        </v-tab-item>
                        <v-tab-item :class="$vuetify.breakpoint.smAndUp ? 'mx-8' : ''"
                            v-if="this.$store.getters.vendor?.licensingDetails?.allowedModules?.indexOf('reward-system') > -1">
                            <v-checkbox v-model="vendor.enableRewards" label="Enable Reward Points"></v-checkbox>
                            <v-flex v-if="vendor.enableRewards">
                                <label class="subtitle-1"><b>Reward Type : </b></label>
                                <v-row class="mx-1">
                                    <v-checkbox v-model="vendor.enableBothRewardType"
                                        label="Enable both reward type (Coupon and Reward Points)"></v-checkbox>
                                </v-row>
                                <label class="subtitle-1"><b>Registration and Reward Points : </b></label>
                                <v-row class="mx-1 my-3">
                                    <v-text-field label="Single Reward Point Cost" type="number"
                                        v-model.number="vendor.rewardSetting.singlePointCost"></v-text-field>
                                    <v-text-field :class="$vuetify.breakpoint.smAndUp ? 'mx-4' : ''"
                                        label="Points on new User registration" type="number"
                                        v-model.number="vendor.rewardSetting.registrationPoint"></v-text-field>
                                    <v-text-field label="Points on referral" type="number"
                                        v-model.number="vendor.rewardSetting.referralPoint"></v-text-field>
                                </v-row>
                                <label class="subtitle-1"><b>Reward Points on Order: </b></label><br><br>
                                <label>Rewards on order can be alloted through the following stratergy :</label>
                                <ol>
                                    <li>Slab<br>
                                        <ul>
                                            <li>Order between ₹100-₹500 give 5 reward points</li>
                                            <li>Order between ₹500-₹1000 give 10 reward points </li>
                                            <li>Orders above ₹1000 give 20 reward points</li>
                                        </ul>
                                    </li>
                                    <li>Percent<br>
                                        <ul>
                                            <li>Order will give a specific percent as reward points<br>Ex- if 10% points
                                                are to be alloted when
                                                order is placed,then on order of ₹1000 user will get 100 reward points
                                            </li>
                                        </ul>
                                    </li>
                                    <li>FlatAmount<br>
                                        <ul>
                                            <li>All orders will allot fixed reward points<br>
                                                Ex-if FlatAmount is 10, then order of any amount will give 10 reward
                                                points</li>
                                        </ul>
                                    </li>
                                </ol>
                                <v-autocomplete class="mt-3" label="Order point Type"
                                    v-model="vendor.rewardSetting.orderPoint.pointType"
                                    :items="orderPointType"></v-autocomplete>
                                <v-flex my-4 v-if="vendor.rewardSetting.orderPoint.pointType == orderRewardType.SLAB">
                                    <v-row>
                                        <v-flex xs3 sm3 mx-3>
                                            <v-text-field label="Min Order Amount" type="number"
                                                v-model.number="minOrderTotal"></v-text-field>
                                        </v-flex>
                                        <v-flex xs3 sm3 mx-2>
                                            <v-text-field label="Max Order Amount" type="number"
                                                v-model.number="maxOrderTotal"></v-text-field>
                                        </v-flex>
                                        <v-flex xs4 sm2 mx-2>
                                            <v-checkbox v-model="noMaxLimit" label="No Max Limit"></v-checkbox>
                                        </v-flex>
                                        <v-flex xs4 sm2 mx-3>
                                            <v-text-field label="Reward Points" type="number"
                                                v-model.number="point"></v-text-field>
                                        </v-flex>
                                        <v-btn xs1 class="mx-6 my-2" @click="addToSlab()" :style="theme" fab small>
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-row>
                                    <label class="body-1">Slab Details : </label>
                                    <v-layout wrap
                                        v-if="vendor.rewardSetting.orderPoint.slabs && vendor.rewardSetting.orderPoint.slabs.length > 0">
                                        <v-flex mt-3>
                                            <v-simple-table class="pb-4">
                                                <thead>
                                                    <tr>
                                                        <th class="text-right">Slab No</th>
                                                        <th class="text-right">Min Order Total</th>
                                                        <th class="text-right">Max Order Total</th>
                                                        <th class="text-right">Points</th>
                                                        <th class="text-center">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in vendor.rewardSetting.orderPoint.slabs"
                                                        :key="index">
                                                        <td class="text-right">{{ index + 1 }}</td>
                                                        <td class="text-right">{{ item.minOrderTotal }}</td>
                                                        <td class="text-right">{{ item.maxOrderTotal == -1 ? 'No \
                                                            Limit': item.maxOrderTotal }}
                                                        </td>
                                                        <td class="text-right">{{ item.point }}</td>
                                                        <td class="text-center">
                                                            <v-icon @click="editSlab(index)"
                                                                :small="$vuetify.breakpoint.xsOnly">mdi-pencil</v-icon>
                                                            <v-icon @click="removeSlab(index)"
                                                                :small="$vuetify.breakpoint.xsOnly">mdi-delete</v-icon>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </v-simple-table>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                                <v-flex v-if="vendor.rewardSetting.orderPoint.pointType == orderRewardType.PERCENT">
                                    <v-text-field label="Point on order percent" type="number"
                                        v-model.number="vendor.rewardSetting.orderPoint.percent"></v-text-field>
                                </v-flex>
                                <v-flex v-if="vendor.rewardSetting.orderPoint.pointType == orderRewardType.FLATAMOUNT">
                                    <v-text-field label="Flat reward Points" type="number"
                                        v-model.number="vendor.rewardSetting.orderPoint.flatAmount"></v-text-field>
                                </v-flex>
                                <label class="subtitle-1"><b>Reward Redeemtion : </b></label>
                                <v-row class="mx-1 my-3">
                                    <v-text-field label="Redeem Points After total points collected" type="number"
                                        v-model.number="vendor.rewardSetting.redeemOn.point"></v-text-field>
                                    <v-text-field :class="$vuetify.breakpoint.xsOnly ? '' : 'mx-3'"
                                        label="Max points to Redeem on order Percent" type="number"
                                        v-model.number="vendor.rewardSetting.redeemOn.maxPercent"></v-text-field>
                                    <v-text-field label="Max points to be Redeem on single order" type="number"
                                        v-model.number="vendor.rewardSetting.redeemOn.maxValue"></v-text-field>
                                </v-row>
                            </v-flex>
                        </v-tab-item>
                        <v-tab-item :class="$vuetify.breakpoint.smAndUp ? 'mx-8' : ''">
                            <v-flex>
                                <v-simple-table>
                                    <thead>
                                        <tr>
                                            <th style=font-size:16px>Notification</th>
                                            <th style=font-size:14px>Emall</th>
                                            <th style=font-size:14px>SMS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Notify me on new order</td>
                                            <td>
                                                <v-checkbox v-model="vendor.emailNotification"></v-checkbox>
                                            </td>
                                            <td>
                                                <v-checkbox v-model="vendor.smsNotification"></v-checkbox>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-simple-table>
                            </v-flex>
                        </v-tab-item>
                        <v-tab-item
                            v-if="this.$store.getters.vendor?.licensingDetails?.allowedModules?.indexOf('order') > -1">
                            <v-checkbox v-model="vendor.razorPay.enable" label="Enable RazorPay"></v-checkbox>
                            <v-flex v-if="vendor.razorPay.enable">
                                <a href="https://razorpay.com/" target="_blank">
                                    <v-btn small :style="themeInverted">Create RazorPay Account</v-btn>
                                </a>
                                <a href="https://dashboard.razorpay.com/app/keys" target="_blank">
                                    <v-btn small :class="$vuetify.breakpoint.smAndUp ? 'ml-2' : 'mt-3'"
                                        :style="themeInverted">Get RazorPay Api
                                        Keys</v-btn>
                                </a>
                                <v-text-field class="mt-3" label="Enter key Id" v-model="vendor.razorPay.key_id"
                                    :rules="fieldRequired" required></v-text-field>
                                <v-text-field label="Enter Key Secret" v-model="vendor.razorPay.key_secret"
                                    :rules="fieldRequired" required></v-text-field>
                                <v-flex mt-4>
                                    <label>User Payment Options</label>
                                    <v-layout wrap>
                                        <v-flex sm3>
                                            <v-checkbox v-model="vendor.paymentOption.online"
                                                label="Pay Online"></v-checkbox>
                                        </v-flex>
                                        <v-flex sm3>
                                            <v-checkbox v-model="vendor.paymentOption.cash"
                                                label="Cash on Delivery"></v-checkbox>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-flex>
                        </v-tab-item>
                    </v-tabs>
                    <alert-message v-if="msg" :error="msg"></alert-message>
                    <v-flex text-right>
                        <v-btn class="mr-4" :style="theme" @click="submit()">Save</v-btn>
                        <v-btn class="mr-4" :style="themeInverted" outlined @click="$router.go(-1)">Cancel</v-btn>
                    </v-flex>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import appConstants from '@/utils/appConstants'
import CustomerOrder from '../../../components/CustomerOrder.vue';
export default {
    components: {
        CustomerOrder
    },
    data() {
        return {
            vendor: {
                businessName: '',
                description: '',
                email: '',
                taxPercent: '',
                showBanner: true,
                bannerImages: "",
                logo: "",
                mobileNo: '',
                address: '',
                delivery: [],
                imageInRow: 0,
                storeStatus: "",
                counterStatus: "",
                storeWorkflowId: "",
                counterWorkflowId: "",
                negativeStock: true,
                deliveryMsg: '',
                fontColor: '',
                fontFamily: '',
                themeColor: '',
                themeUpdated: false,
                whatsappNo: "",
                whatsappMsg: "",
                facebook: "",
                twitter: "",
                instagram: "",
                pickupName: '',
                pickupAddress: '',
                enablePickup: false,
                deliveryCharge: 0,
                deliveryDays: 1,
                freeDeliveryAbove: 0,
                emailNotification: false,
                smsNotification: false,
                productCardMobile: 0,
                razorPay: {
                    enable: false,
                    key_id: '',
                    key_secret: ''
                },
                rewardSetting: {
                    singlePointsCost: 1,
                    registrationPoint: 0,
                    referralPoint: 0,
                    orderPoint: {
                        pointType: '',
                        slabs: [],
                        percent: 0,
                        flatAmount: 0
                    },
                    redeemOn: {
                        point: 0,
                        maxPercent: -1,
                        maxValue: 0
                    }
                },
                enableBothRewardType: false,
                enableRewards: false,
                storeUI: 0,
                paymentOption: {
                    online: false,
                    cash: false
                },
                enableSaleChallan: false,
                supplyWorkflowId: '3'
            },
            noMaxLimit: false,
            show: false,
            deliveryOptions: [{
                code: 'pick-up-from-shop',
                label: 'Pick Up From Shop'
            },
            {
                code: 'home-delivery',
                label: 'Home Delivery'
            }
            ],
            orderPointType: ['SLAB', 'PERCENT', 'FLATAMOUNT'],
            slabCount: 0,
            maxOrderTotal: 0,
            minOrderTotal: 0,
            point: 0,
            id: 0,
            bannerImages: [],
            logo: null,
            icon: 'mdi-pencil',
            selectedOption: {},
            imageInRow: [{
                name: "Single Card",
                item: 1,
                img: require("@/assets/single-Item.png")
            },
            {
                name: "Two Card",
                item: 2,
                img: require("@/assets/two-Items.png")
            }
            ],
            productCardMobile: [{
                name: "Horizontal Card",
                item: 1,
                img: require("@/assets/horizontal_card.png")
            },
            {
                name: "Vertical Card",
                item: 2,
                img: require("@/assets/vertical_card.jpg")
            }
            ],
            storeUI: [{
                name: appConstants.OLD_STORE_UI,
                item: 1,
            }, {
                name: appConstants.NEW_STORE_UI,
                item: 2,
            }],
            workflows: [],
            supplyWorkflows: [],
            storeSteps: [],
            counterSteps: [],
            supplySteps: [],
            progress: 0,
            fontFamily: 'Open Sans',
            pickupName: '',
            pickupAddress: '',
            enablePickup: false,
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.vendor = await this.getItem(appConstants.VENDORSETTING_API + "/" + this.id)
            if (this.vendor.fontFamily != undefined)
                this.fontFamily = this.vendor.fontFamily
            this.setDeliveryOptions()
            this.deliveryOptionUpdated()
            this.workflows = await this.getItem(appConstants.WORKFLOW_API)
            this.supplyWorkflows = await this.getItem(appConstants.SUPPLY_WORKFLOW)
            this.supplyWorkflows = this.supplyWorkflows.filter(rec => rec.show == true)
            if (this.vendor.storeWorkflowId)
                this.updateStoreStatus()
            if (this.vendor.counterWorkflowId)
                this.updateCounterStatus()
            if (!this.vendor.whatsappMsg)
                this.vendor.whatsappMsg = this.getDefaultWhatsappMsg()
        },
        setDeliveryOptions() {
            if (this.vendor.delivery.length > 0) {
                this.vendor.delivery.forEach(option => {
                    this.deliveryOptions.forEach(rec => {
                        if (option.code == rec.code)
                            rec.label = option.label
                    })
                })
            }
        },
        async submit() {
            if (this.$refs.form.validate()) {
                try {
                    this.checkPaymentOption()
                    this.vendor.fontColor = this.vendor.fontColor.hex ? this.vendor.fontColor.hex : this.vendor.fontColor
                    this.vendor.themeColor = this.vendor.themeColor.hex ? this.vendor.themeColor.hex : this.vendor.themeColor
                    if (this.vendor.fontFamily != '' || this.vendor.fontColor != '' || this.vendor.themeColor != '')
                        this.vendor.themeUpdated = true
                    if (this.vendor.delivery.length == 0 && !this.vendor.deliveryMsg)
                        this.vendor.deliveryMsg = 'Currently not accepting online Orders'
                    await this.uploadFormData()
                    this.vendor = await this.getItem(appConstants.VENDORSETTING_API + "/" + this.$store.getters.vendor._id)
                    this.$store.dispatch("actionUpdateVendorSetting", this.vendor)
                    this.$router.go(-1)
                } catch (error) {
                    this.handleError(error)
                    this.msg = error.response.data
                }
            }
        },
        /*
        If online payment is enable vendor can choose between online payment and cash on delivery or both
        else cash on delivery should be enabled
        */
        checkPaymentOption() {
            if (!this.vendor.razorPay.enable || !this.vendor.paymentOption.online)
                this.vendor.paymentOption.cash = true
            if (!this.vendor.razorPay.enable && this.vendor.paymentOption.online)
                this.vendor.paymentOption.online = false
        },
        async uploadFormData() {
            var config = {
                headers: this.commonHeader().headers,
                onUploadProgress: (progressEvent) => {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.progress = percentCompleted
                }
            }
            try {
                let formData = this.convertToFormData(this.vendor, this.bannerImages, 'bannerImages')
                this.vendor = await this.putItem(appConstants.VENDORSETTING_API + "/" + this.vendor._id, formData, config)
                let formDataWithLogo = this.convertToFormData(this.vendor, [this.logo], 'logo')
                if (this.logo != null) {
                    await this.putItem(appConstants.VENDORSETTING_API + "/logo/" + this.vendor._id, formDataWithLogo, config)
                }
            } catch (error) {
                throw error
            }
        },
        removeBannerImage(index) {
            this.vendor.bannerImages.splice(index, 1)
        },
        removeLogo(index) {
            this.vendor.logo = ''
        },
        toggledDialogue() {
            this.show = !this.show
        },
        editLabel(item) {
            this.selectedOption = item
            this.toggledDialogue()
        },
        saveLabel() {
            this.deliveryOptions.forEach(rec => {
                if (rec.code == this.selectedOption.code)
                    rec.label = this.selectedOption.label
            });
            this.toggledDialogue()
        },
        updateStoreStatus() {
            var currentWorkflow = this.workflows.findIndex(rec => rec._id == this.vendor.storeWorkflowId)
            this.storeSteps = this.workflows[currentWorkflow].steps
        },
        updateCounterStatus() {
            var currentWorkflow = this.workflows.findIndex(rec => rec._id == this.vendor.counterWorkflowId)
            this.counterSteps = this.workflows[currentWorkflow].steps
        },
        deliveryOptionSelected() {
            if (this.vendor.delivery !== undefined && this.vendor.delivery.length > 0)
                return true
            else
                return false
        },
        updateFont(event) {
            this.vendor.fontFamily = this.fontFamily = event.family
        },
        deliveryOptionUpdated() {
            this.enablePickup = this.vendor.delivery.length > 0 && this.vendor.delivery[0].code == 'pick-up-from-shop' || this.vendor.delivery.length > 1 && this.vendor.delivery[1].code == 'pick-up-from-shop'
        },
        addPickup() {
            var pickupExists = false
            this.vendor.pickupPoints.forEach(rec => {
                if (rec.name == this.pickupName)
                    pickupExists = true
            })
            if (!pickupExists) {
                this.vendor.pickupPoints.push({
                    'name': this.pickupName,
                    'address': this.pickupAddress
                })
                this.pickupName = ''
                this.pickupAddress = ''
            }
            if (pickupExists)
                alert(this.pickupName + ' is already a Pickup Point.')
        },
        removePickupPoint(index) {
            this.vendor.pickupPoints.splice(index, 1)
        },
        editPickup(index) {
            this.pickupName = this.vendor.pickupPoints[index].name
            this.pickupAddress = this.vendor.pickupPoints[index].address
            this.removePickupPoint(index)
        },
        addToSlab() {
            this.vendor.rewardSetting.orderPoint.slabs.push({
                'minOrderTotal': this.minOrderTotal,
                'maxOrderTotal': this.noMaxLimit ? -1 : this.maxOrderTotal,
                'point': this.point
            })
            this.minOrderTotal = this.maxOrderTotal = this.point = 0
        },
        editSlab(index) {
            this.maxOrderTotal = this.vendor.rewardSetting.orderPoint.slabs[index].maxOrderTotal
            this.minOrderTotal = this.vendor.rewardSetting.orderPoint.slabs[index].minOrderTotal
            this.point = this.vendor.rewardSetting.orderPoint.slabs[index].point
            this.removeSlab(index)
        },
        removeSlab(index) {
            this.vendor.rewardSetting.orderPoint.slabs.splice(index, 1)
        },
        updateSupplyStatus() {
            var currentWorkflow = this.workflows.findIndex(rec => rec._id == this.vendor.supplyWorkflowId)
            this.supplySteps = this.workflows[currentWorkflow].steps
        },
    },
}
</script>
